import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_EVENTS_CREATE } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import ViewHeader from 'Components/layout/panel/ViewHeader';
import Editor from 'Components/admin/events/Editor';

export default class AdminEventsCreate extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    render() {
        const { location, history } = this.props;

        return (
            <StyledComponent
                className="admin-events-create"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_EVENTS_CREATE.path),
                        label: 'Nowe wydarzenie',
                    }]}
                >
                    <ViewHeader
                        title="Nowe wydarzenie"
                    />
                    <Editor
                        location={location}
                        history={history}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}